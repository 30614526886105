import { useCallback, useEffect, useState } from "react";

import { PerformanceBox } from "../../components/PerformanceBox";
import Layout from "../../components/Layout";
import MUISelect from "../../components/MUISelect";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem } from "@mui/material";
import DateModal from "../../components/DateModal";
import useAnalytics from "../../hooks/useAnalytics";
import useAccounts from "../../hooks/useAccounts";
import useBets from "../../hooks/useBets";
import { setBalance, setCurrency } from "../../store/slices/general";
import { renderMoney } from "../../utils/helpers";

export const Analytics = () => {
  const dispatch = useDispatch<any>();
  const {
    getAnalyticsData,
    analytics,
    loading: aLoading,
    chartData,
  } = useAnalytics();
  const { loading: accLoading, getPandLData, pLData } = useAccounts();
  const {
    setRange,
    loading: betLoadings,
  } = useBets({ getAll: false });
  const [graphDates, setGraphDates] = useState(false);

  const { global } = useSelector((state: any) => state);
  const { currency } = global;
  const getPerformanceData = (params: any) => {
    const dates = params[0];
    getAnalyticsData(dates);
  };

  const getAccountsCb = (params: any) => {
    const dates = params[0];
    setRange(dates);
    setGraphDates(dates);
  };

  useEffect(() => {
    getPandLData(graphDates);
  }, [currency, graphDates]);

  const renderCoins = useCallback(() => {
    return global.accounts?.map((item: any, index: number) => {
      return (
        <MenuItem
          key={index}
          className=" border-b-darkBlueGray  text-lightGray2 h-[56px]"
          value={item.symbol}
          sx={{
            fontSize: "16px",
            paddingX: "0px",
            borderBottom:
              global.accounts.length - 1 === index ? "" : "1px solid",
            borderColor: "#444869",
          }}
        >
          <div className="px-2">{item.symbol}</div>
        </MenuItem>
      );
    });
  }, [global]);

  const renderPnL = (array: any, currency: string) => {
    const matched = array?.find((item: any) => item?.symbol === currency);
    if (!matched) {
      return "N/A";
    }
    return renderMoney(Number(matched.pnl), currency) + " " + currency;
  };

  return (
    <div className="w-full">
      <Layout
        active={2}
        page="Analytics"
        loading={betLoadings || aLoading || accLoading}
      >  
        <div className="1lg:w-[90%] md:flex md:gap-[30px] md:flex-col w-[1140px] mx-auto mt-[36px] mb-[60px]">
          <div className="p-[30px] mt-[30px] mb-[30px] md:mt-0 rounded-[24px] bg-darkBluish border-[1px] border-darkGray2 sm:p-4 relative">
            <div className="flex flex-row sm:flex-col items-center justify-between">
              <h1 className="text-white md:text-[18px] font-bold text-[25px]">
                Account stats
              </h1>
              <div className="flex sm:w-full justify-between sm:mt-5 gap-4">
                <div className="sm:flex-1">
                  <MUISelect
                    className={"sm:w-full"}
                    onChange={(e: any) => {
                      dispatch(setCurrency(e.target.value));
                      dispatch(setBalance(e.target.value));
                    }}
                    value={currency}
                  >
                    {renderCoins()}
                  </MUISelect>
                </div>

                <div className="sm:flex-1">
                  <DateModal current="All Time" cb={getAccountsCb} />
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-1  mt-4 gap-4">
              <PerformanceBox
                heading="P&L"
                number={renderPnL(pLData, currency)}
                chartType="pie"
                hoverData="Profit / Loss for the selected period"
              />
            </div>
          </div>
          <div className="p-[30px] rounded-[24px] bg-darkBluish border-[1px] border-darkGray2 sm:p-4 relative">
            <div className="flex items-center justify-between">
              <h1 className="text-white md:text-[18px] font-bold text-[25px]">
                Overall Performance
              </h1>
              <div className="flex gap-4">
                <div className="h-[50px]">
                  <DateModal
                    current="All Time"
                    cb={(e: any) => getPerformanceData(e)}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3  mt-4 gap-4 lg:grid-cols-2 md:!grid-cols-1">
              <PerformanceBox
                heading="Compound Return (P&L)"
                hoverData="The overall return on investment when gains are reinvested, calculated over the selected period."
                number={`${Number(
                  Number(analytics.compound_return) * 100
                ).toFixed(2)}%`}
              />
              <PerformanceBox
                heading="Sharpe Ratio"
                hoverData="A measure of risk-adjusted return. Higher values indicate better performance. Uses monthly returns."
                number={`${
                  analytics.monthly_sharpe_ratio == null
                    ? "N/A"
                    : Number(analytics.monthly_sharpe_ratio).toFixed(2)
                }`}
              />
              <PerformanceBox
                heading="Largest Drawdown"
                hoverData="The largest peak-to-trough decline in the value of the fund over the selected period. (Uses monthly returns)"
                number={`${
                  analytics.largest_drawdown == null
                    ? "N/A"
                    : Number(Number(analytics.largest_drawdown) * 100).toFixed(
                        2
                      ) + "%"
                }`}
              />
              <PerformanceBox
                heading="Winning bets %"
                hoverData="The percentage of bets that resulted in a profit over the selected period."
                number={`${Number(
                  Number(analytics.winning_bets_ratio) * 100
                ).toFixed(2)}%`}
              />
              <PerformanceBox
                heading="Average Monthly Return"
                hoverData="The average return on investment per month over the selected period."
                number={`${Number(
                  Number(analytics.monthly_return) * 100
                ).toFixed(2)}%`}
              />
              <PerformanceBox
                heading="Annualised Return"
                hoverData="The average return on investment per year over the selected period."
                number={`${Number(
                  Number(analytics.yearly_return) * 100
                ).toFixed(2)}%`}
              />
              <div className="col-span-full">
                {chartData && (
                  <PerformanceBox
                    chartsData={chartData}
                    heading="Compound Profit"
                    number=""
                    chartType="bar"
                    hoverData="Compound Profit over the selected period."
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
